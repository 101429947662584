<template>
  <b-modal
    id="maid-modal"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
  >
    <div class="close-btn" @click="closeModal">
      <button type="button" class="close" aria-label="Close">
        <i class="fas fa-times" style="font-size: 0.70em; line-height: 44px"></i>
      </button>
    </div>
    <template slot="default" v-if="isShowModal">
      <b-row>
        <b-col style="z-index: 0">
          <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" fade indicators>
            <b-carousel-slide
              alt="Maid Cover Image"
              img-src="https://dev.houseofdev.tech/assets/images/maid-cover-1.jpg"
              class="custom-carousel"
              v-if="!hasCoverImages"
            ></b-carousel-slide>
            <b-carousel-slide
              alt="Maid Cover Image"
              img-src="https://dev.houseofdev.tech/assets/images/maid-cover-2.jpg"
              class="custom-carousel"
              v-if="!hasCoverImages"
            ></b-carousel-slide>
            <b-carousel-slide
              alt="Maid Cover Image"
              img-src="https://dev.houseofdev.tech/assets/images/maid-cover-3.jpg"
              class="custom-carousel"
              v-if="!hasCoverImages"
            ></b-carousel-slide>
            <div 
              @click="imageView = index ; $bvModal.show('image-view-modal')"
              v-for="(image, index) in maidDetail.cover_images" 
              :key="index"
            >
              <b-carousel-slide
                alt="Maid Cover Image"
                :img-src="image"
                class="custom-carousel"
              ></b-carousel-slide>
            </div>
          </b-carousel>
          <b-modal
            id="image-view-modal"
            hide-header
            hide-footer
            centered
            size="xl"
          >
              <i @click="$bvModal.hide('image-view-modal')" class="fas fa-times" style="font-size: 1.8em; color: white; position: absolute; top: 0; right: 0; cursor: pointer;"></i>
              <b-img
                style="width: calc(100% - 48px); height : calc(100% - 48px); object-fit: contain; margin: 24px; max-width: calc(100vw - 48px); max-height: calc(100vh - 48px - 3.5rem);"
                :src="maidDetail.cover_images[imageView]"
                alt="Maid Cover Image"    
              ></b-img>
              <i
              v-if="imageView < maidDetail.cover_images.length - 1 && maidDetail.cover_images.length > 1"
              @click="imageView++"
              class="fa fa-chevron-right"
              style="
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                font-size: 2em;
                color: white;
                cursor: pointer;
              "
            ></i>
            <i
              v-if="imageView > 0 && maidDetail.cover_images.length > 1"
              @click="imageView--"
              class="fa fa-chevron-left"
              style="
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                font-size: 2em;
                color: white;
                cursor: pointer;
              "
            ></i>
          </b-modal>
          <div class="social-icon d-md-flex d-none">
            <social-sharing :url="shareURL" inline-template>
              <div>
                <network network="facebook">
                  <i class="fas fa-share-alt m-2"></i>
                </network>
              </div>
            </social-sharing>
            <i
              v-if="isLoggedIn"
              :class="{
                far: !isFavoriteMaid,
                ['fas text-red']: isFavoriteMaid,
              }"
              class="fa-heart m-2  "  v-b-tooltip.hover :title="$t('form_label.add_to_fav')"
              @click="toggleFavMaid"
            ></i>
          </div>
        </b-col>
        <b-col md="6" class="maid-modal-detail">
          <b-row class="w-100">
            <b-col md="12" sm="12" class="mb-3">
              <div class="social-icon-mobile">
                <social-sharing :url="shareURL" inline-template>
                  <div>
                    <network network="facebook">
                      <i class="fas fa-share-alt"></i>
                    </network>
                  </div>
                </social-sharing>
                <i
                  v-if="isLoggedIn"
                  :class="{
                    far: !isFavoriteMaid,
                    ['fas text-red']: isFavoriteMaid,
                  }"
                  class="fa-heart" v-b-tooltip.hover :title="$t('form_label.add_to_fav')"
                  @click="toggleFavMaid"
                ></i>
              </div>
              <div class="maid-contact">
                <div class="d-flex flex-column">
                  <MaidAvatar
                    :level="maidDetail.level"
                    :url="maidDetail.user.image_url"
                    size="xxl"
                    style="cursor: pointer"
                    @click.native="showMaidImage"
                  />

                  <img
                    src="./../../assets/images/vaccine.svg"
                    alt=""
                    v-if="maidDetail.vaccinated"
                    class="pt-2 vaccine_avatar"
                  />
                </div>
                <div class="contact-detail w-100">
                  <p class="maid-name mb-1">
                    {{ maidDetail.user.nick_name ? `"${maidDetail.user.nick_name}"` : `` }}
                    {{ `${maidDetail.user.first_name} ${maidDetail.user.last_name}` }}
                    <b-img
                      :src="require('../../assets/images/icons/verify-icon.svg')"
                      v-if="maidDetail.criminal_checked"
                    ></b-img>
                  </p>
                  <div class="rating d-flex align-items-center flex-wrap mb-2 justify-content-center justify-content-md-start" style="height: auto !important">
                    <div class="mr-1 text-nowrap">
                      {{
                        maidDetail.rating ? parseFloat(maidDetail.rating).toFixed(1) : $t('alias.no_rating')
                      }}
                    </div>
                    <star-rating
                      :read-only="true"
                      :rating="starRating"
                      :max-rating="1"
                      :round-start-rating="true"
                      :show-rating="false"
                      :star-size="16"
                      class="mt-n1"
                    >
                    </star-rating>                
                    <div class="ml-1 mr-2 text-nowrap">
                      {{ maidDetail.no_of_reviews ? maidDetail.no_of_reviews : 0 }}
                      {{ $t('alias.reviews') }}
                    </div>
                    <div class="text-nowrap" v-b-tooltip.hover :title="$t('form_label.repeat_rate')">
                      <img src="./../../assets/images/heart-icon-jst.png" alt="heart icon" width="16px" style="margin-top: -2.5px;"/>
                      {{ repeatRate }} %
                    <br />
                    </div>
                    <!-- <div class="d-flex">
                      <div v-if="maidDetail.availability_status == 'LIMITED_AVAILABILITY'" class="limited">
                        {{ this.$t('modal.maid_detail.limited_availability') }}
                      </div>
                      <div  v-else-if="maidDetail.availability_status == 'LOT_OF_AVAILABILITY'" class="a_lot">
                        {{ this.$t('modal.maid_detail.a_lot_of_availability') }}
                      </div>
                    </div>   -->
                  </div>                
                  <div class="d-flex flex-column align-items-start mt-1 maid-option">
                    <div class="d-flex align-items-center mb-2 flex-wrap maid-option-div" style="gap: 1px;">
                      <span style="white-space: nowrap" class="mr-2 font-small maid-option-span"
                        >{{ $t('form_label.work_type') }} :</span
                      >
                      <!-- <span                        
                        class="mr-2"
                        style="position: relative; width: 20px; height: 20px;"
                        v-if="hasCleaningService"
                        >
                      <b-img
                      style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                        height="20"
                        width="20"
                        :src="require('../../assets/images/icons/cleaning-blue1.svg')"
                      ></b-img>
                      <b-img
                        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                        height="15"
                        width="15"
                        :src="require('../../assets/images/icons/cleaning-blue2.svg')"
                        class="opacity-animation"
                      ></b-img>
                      <b-img
                      style="position: absolute; top: 5px; left: 3px;"
                        height="13"
                        width="13"
                        :src="require('../../assets/images/icons/cleaning-blue3.svg')"
                        class="rotate-animation"
                      ></b-img>
                    </span> -->
                      <div class="d-flex align-items-center flex-wrap" style="gap: 1.5px;">
                      <div class="d-flex" >
                      <b-img
                        class="mr-2"
                        height="20"
                        width="20"
                        :src="require('../../assets/images/icons/cleaning-blue.svg')"
                        v-if="hasCleaningService"
                      ></b-img>
                      <b-img
                        class="mr-2"
                        height="20"
                        width="20"
                        :src="require('../../assets/images/icons/cleaning-gray.svg')"
                        v-else
                      ></b-img>
                      <span
                        :class="{
                          'text-primary': hasCleaningService,
                          'text-light-gray': !hasCleaningService,
                        }"
                        class="mr-2 font-small"
                        >{{ $t('services.cleaning') }}</span
                      >
                      </div>
                      <div class="d-flex" >
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/ironing-blue.svg')"
                        v-if="hasIroningService"
                      ></b-img>
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/ironing-gray.svg')"
                        v-else
                      ></b-img>
                      <span
                        :class="{
                          'text-primary': hasIroningService,
                          'text-light-gray': !hasIroningService,
                        }"
                        class="font-small"
                        >{{ $t('services.ironing') }}</span
                      >
                      </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mb-2 flex-wrap maid-option-div" style="gap: 1px;">
                      <span style="white-space: nowrap" class="mr-2 font-small maid-option-span"
                        >{{ $t('form_label.equipment_option') }} :</span
                      >
                      <div class="d-flex" >
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/equipment-blue.svg')"
                        v-if="bringEquipment"
                      ></b-img>
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/equipment-gray.svg')"
                        v-else
                      ></b-img>
                      <span
                        style="white-space: nowrap"
                        :class="{
                          'text-primary': bringEquipment,
                          'text-light-gray': !bringEquipment,
                        }"
                        class="font-small"
                        >{{
                          bringEquipment
                            ? $t('form_label.can_bring_equipment')
                            : $t('form_label.no_equipment')
                        }}</span
                      >
                      </div>
                    </div>
                    <div 
                      v-if="canDriveCar || canDriveMotorcycle"
                      class="d-flex align-items-center mb-2 flex-wrap maid-option-div" 
                      style="gap: 1px"
                    >
                      <span style="white-space: nowrap" class="mr-2 font-small maid-option-span"
                        >{{ $t('form_label.driving') }} :</span
                      >
                      <div class="d-flex align-items-center flex-wrap" style="gap: 1.5px;">
                      <div v-if="canDriveCar" class="d-flex mr-2">
                        <b-img
                          class="mr-1"
                          style="width: 20px; height: 20px;"
                          :src="require('../../assets/images/icons/DRIVE_CAR.svg')"
                        ></b-img>
                        <span style="white-space: nowrap" class="font-small text-primary">{{
                          $t('form_label.driving_car')
                        }}</span>
                      </div>
                      <div v-if="canDriveMotorcycle" class="d-flex">
                        <b-img
                          class="mr-1"
                          style="width: 20px; height: 20px;"
                          :src="require('../../assets/images/icons/DRIVE_MOTORCYCLE.svg')"
                        ></b-img>
                        <span style="white-space: nowrap" class="font-small text-primary">{{
                          $t('form_label.driving_motorcycle')
                        }}</span>
                      </div>
                      </div>
                    </div>
                    <div 
                      v-if="allergicDogs || allergicCats"
                      class="d-flex align-items-center mb-2 flex-wrap maid-option-div" 
                      style="gap: 1px"
                    >
                      <span style="white-space: nowrap" class="mr-2 font-small maid-option-span"
                        >{{ $t('form_label.allergic') }} :</span
                      >
                      <div class="d-flex align-items-center flex-wrap" style="gap: 1px;">
                      <div v-if="allergicDogs" class="d-flex mr-2">
                        <b-img
                          class="mr-1"
                          style="width: 20px; height: 20px;"
                          :src="require('../../assets/images/icons/ALLERGIC_DOG.svg')"
                        ></b-img>
                        <span style="white-space: nowrap" class="font-small text-primary">{{
                          $t('form_label.allergic_dogs')
                        }}</span>
                      </div>
                      <div v-if="allergicCats" class="d-flex">
                        <b-img
                          class="mr-1"
                          style="width: 20px; height: 20px;"
                          :src="require('../../assets/images/icons/ALLERGIC_CAT.svg')"
                        ></b-img>
                        <span style="white-space: nowrap" class="font-small text-primary">{{
                          $t('form_label.allergic_cats')
                        }}</span>
                      </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center flex-wrap maid-option-div" style="gap: 1px;">
                      <span style="white-space: nowrap" class="mr-2 font-small maid-option-span"
                        >{{ $t('form_label.language') }} :</span
                      >
                      <div class="d-flex align-items-center flex-wrap" style="gap: 1px;">
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/lang-TH.svg')"
                        v-if="canSpeakTH"
                      ></b-img>
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/lang-EN.svg')"
                        v-if="canSpeakEN"
                      ></b-img>
                      <b-img
                        class="mr-2"
                        :src="require('../../assets/images/icons/lang-JP.svg')"
                        v-if="canSpeakJP"
                      ></b-img>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <img
                        src="./../../assets/images/vaccine.svg"
                        alt=""
                        v-if="maidDetail.vaccinated"
                        class="pt-2 mt-1 vaccine_avatar_mobile"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-2">
                <div class="description">{{ maidDetail.description }}</div>
              </div>
            </b-col>
            <b-col md="12">
              <div
                class="fb-friend-used d-none d-md-block pl-2 mb-2"
                v-if="friendsWhoUseThisMaid.length > 0"
              >
                <p>My FB Friends who have used this maid</p>
                <b-img
                  :src="friend.image_url"
                  alt="avatar"
                  class="avatar"
                  v-for="(friend, idx) in friendsWhoUseThisMaid"
                  :key="idx"
                ></b-img>
              </div>
            </b-col>
          </b-row>
          <b-form-row class="d-none d-sm-flex">
            <b-col cols="12" lg="6" v-if="shouldShowOneTimeBtn">
              <b-button
                variant="success"
                class="choose-maid-btn my-1"
                block
                @click="chooseMaid('one-time')"
              >
                <b-img
                  alt="one-time-icon"
                  :src="require('../../assets/images/icons/one-time-icon.svg')"
                  class="mr-2"
                />{{ $t('commons.book_one_time') }}
              </b-button>
            </b-col>
            <b-col cols="12" lg="6" v-if="shouldShowMutiPackageBtn">
              <b-button
                variant="warning"
                class="choose-maid-btn my-1"
                block
                @click="chooseMaid('multi-package')"
              >
                <b-img
                  alt="multi-package-icon"
                  :src="require('../../assets/images/icons/multi-package-white.svg')"
                  class="mr-2"
                />{{ $t('commons.book_multi_package') }}
              </b-button>
            </b-col>
          </b-form-row>

          <div class="buttons-mobile">
            <b-button
              variant="success"
              class="choose-maid-btn my-1"
              block
              @click="chooseMaid('one-time')"
              v-if="shouldShowOneTimeBtn"
            >
              <b-img alt="one-time-icon" :src="require('../../assets/images/icons/one-time-icon.svg')" class="mr-2" />{{
                $t('commons.book_one_time')
              }}
            </b-button>
            <b-button
              variant="warning"
              class="choose-maid-btn my-1"
              block
              @click="chooseMaid('multi-package')"
              v-if="shouldShowMutiPackageBtn"
            >
              <b-img
                alt="multi-package-icon"
                :src="require('../../assets/images/icons/multi-package-white.svg')"
                class="mr-2"
              />{{ $t('commons.book_multi_package') }}
            </b-button>
          </div>

          <div class="fb-friend-used d-md-none d-xs-block" v-if="friendsWhoUseThisMaid.length > 0">
            <p>My FB Friends who have used this maid</p>
            <b-img
              :src="friend.image_url"
              alt="avatar"
              class="avatar"
              v-for="(friend, idx) in friendsWhoUseThisMaid"
              :key="idx"
            ></b-img>
          </div>

          <b-form-row class="mt-3"> </b-form-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <div class="px-3 px-md-0">
            <!-- Maps (Maid's Location) -->
            <div class="mb-3">
              <p class="mb-3 text-capitalize" style="font-size: 18px">
                {{ maidLocation }}
              </p>
              <!-- <div id="map" ref="maps" style="height: 350px"></div> -->
              <div class="map-container">
              <iframe
                v-if="true"
                class="map-iframe"
                :src="`https://maps.google.com/maps?q=${centerMaps.lat}, ${centerMaps.lng}&t=&z=14&ie=UTF8&iwloc=&output=embed`"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
              </div>
            </div>
            <!-- Calendar -->
            <div style="position: relative;">
            <v-calendar
              :key="calendarComponentKey"
              :locale="$i18n.locale"
              ref="calendar"
              class="v-calendar-custom"
              is-expanded
              :min-date="minDate"
              :attributes="calendarAttrs"
              @dayclick="onSelectedDate"
              @update:from-page="onMonthChanged"
            ></v-calendar>
            <div style="top: 0; right: 50%; position: absolute; transform: translateX(550%);">
               <b-img
                :src="require('@/assets/images/information-icon-black.svg')"
                v-b-tooltip.hover.html="$t('form_label.calendar_tooltip')"
                @click.stop
                style="z-index: 100;"
                class="mt-3"
              ></b-img>
            </div>
            </div>
            <CalendarDetail
              v-if="selectedDate && maidAvailableTimeSlots.length > 0"
              :selectedDate="selectedDate"
              :maidCalendars="maidAvailableTimeSlots"
            />
          </div>
        </b-col>
        <b-col lg="6">
          <hr />
          <div class="px-3 px-md-0">
            <div class="comment" v-for="review in reviews" :key="review.id">
              <ExpandableReview :review="review" @delete-review="deleteReview" />
            </div>

            <b-pagination
              :total-rows="totalReviews"
              :perPage="noOfReviewsPerPage"
              v-model="currentPage"
              class="pagination"
              v-if="reviews.length > 0"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </template>
    <template slot="default" v-else>
      <b-row class="justify-content-center" style="margin-top: 30% !important">
        <LoadingSpinner label="Loading..."></LoadingSpinner>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { Vue, Component, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import StarRating from 'vue-star-rating'
import ApiClient from '../../services'
import MaidAvatar from '../MaidAvatar'

import ReviewItem from '../ReviewItem'
import ExpandableReview from '../ExpandableReview'
import LoadingSpinner from '../LoadingSpinner'

import { GOOGLE_MAPS_API_KEY } from '../../config/constants'
import { isToday } from '../../utils'
import { getDisabledDateFromTimeSlot, isDateInTimeSlots } from '../../helpers/calendar'
import CalendarDetail from '../CalendarDetail'

import { defaultMapsCenter } from '../../helpers/maps'
import moment from 'moment'

const MaidStore = createNamespacedHelpers('maid')
const BookingStore = createNamespacedHelpers('booking')
const AuthStore = createNamespacedHelpers('auth')
const CalendarStore = createNamespacedHelpers('calendar')
@Component({
  components: {
    StarRating,
    MaidAvatar,
    CalendarDetail,
    ReviewItem,
    ExpandableReview,
    LoadingSpinner,
  },
  methods: {
    ...BookingStore.mapActions(['setMaidForBooking']),
    ...MaidStore.mapActions(['fetchMyFavoriteMaids', 'unSaveToFavMaid', 'saveToFavMaid']),
    ...MaidStore.mapMutations(['resetMaidModal']),
    ...CalendarStore.mapActions([
      'fetchMaidCalendarTotalHours',
      'fetchMaidCalendarAllMonth',
      'fetchMaidCalendarAllMonthPublic',
    ]),
    ...CalendarStore.mapMutations(['resetMaidCalendar', 'setCurrentSelectedOnCalendar']),
  },
  computed: {
    ...MaidStore.mapState(['detail', 'isShowModal']),
    ...AuthStore.mapState(['isLoggedIn']),
    ...CalendarStore.mapGetters([
      'calendarTotalHours',
      'baseDisabledHighlight',
      'maidAvailableTimeSlots',
    ]),
  },
  mounted() {
    console.log('baseDisabledHighlight', this.baseDisabledHighlight)
    // this.fetchMaidCalendar()
  },
  updated() {
    // console.log("update", this.$refs.maps);
    if (this.$refs.maps && !this.start) {
      // this.initMaps()
      this.start = true
    }
  },
})
export default class MaidDetailModal extends Vue {
  isShowTooltip = false
  start = false
  currentPage = 1
  noOfReviewsPerPage = 10
  totalReviews = 0
  reviews = []
  isFavoriteMaid = false
  maidDetail = {
    bring_equipment: false,
    location_name: '',
    location_radius: 0,
    location_latitude: 0,
    location_longitude: 0,
    description: '',
    facebook_verified: false,
    criminal_checked: false,
    character_checked: false,
    fcm_token: null,
    cover_images: [],
    service_types: [],
    languages: [],
    rating: 0,
    no_of_reviews: 0,
    total_hours: 0,
    multi_package_hours: 0,
    repeat_rate: 0,
    level: 'No Level',
    user: {
      first_name: '',
      last_name: '',
    },
    total_works: 0,
    selected_date: null,
    selected_time: null,
  }
  isLoad = false
  imageView = ''

  friendsWhoUseThisMaid = []
  // @shown="onModalShown"
  // onModalShown() {
  //   this.$store.commit('maid/setIsShowModal', false)
  // }

  showMaidImage() {
    this.$store.commit('setImages', [this.detail.user.image_url])
  }
  expand() {}

  get languagesLabel() {
    const result = []
    this.maidDetail.languages.forEach((lang) => {
      const langLower = lang.toLowerCase()
      result.push(this.$t(`language.${langLower}`))
    })
    return result.join(' / ')
  }

  get serviceLabel() {
    const result = []
    this.maidDetail.languages.forEach((lang) => {
      const langLower = lang.toLowerCase()
      result.push(this.$t(`language.${langLower}`))
    })
    return result.join(' / ')
  }

  // ============== Calendar ============== //

  disabledDates = []

  get todayHighlight() {
    return {
      key: 'today',
      content: 'blue',
      dates: new Date(...this.$momentTZ().toArray()),
    }
  }

  get currentSelectDateHighlight() {
    return {
      key: 'selectedDay',
      bar: true,
      dates: this.selectedDate ? this.selectedDate : null,
    }
  }

  get firstDot() {
    return {
      key: 'firstDot',
      dot: {
        class: 'custom-dot-gray',
      },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          return !isDisabledDate && d.total_hours >= 2
        })
        .map((d) =>this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get secondDot() {
    return {
      key: 'secondDot',
      dot: { class: 'custom-dot-gray' },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          return d.total_hours >= 4 && !isDisabledDate
        })
        .map((d) => this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get maidLocation() {
    return this.$i18n.locale === 'th'
      ? `ตำแหน่งของ ${this.maidDetail.user.first_name}`
      : `${this.maidDetail.user.first_name}'s Location`
  }

  get selectType() {
    return this.$route.query.type
  }

  get isSelectMaidPage() {
    return this.$route.name === 'SelectMaid'
  }

  get isCallbackMyBooking() {
    return this.$route.query.callback !== undefined && this.$route.query.callback.includes('/my-booking') 
  }

  get isBookingPage() {
    return [
      'BookingOneTime',
      'BookingMultiPackage',
      // 'MyBookingMultiPackage',
      // 'MyBookingOneTime'
    ].includes(this.$route.name)
  }

  get shouldShowMutiPackageBtn() {
    return (
      (this.selectType === 'multi-package' && this.isSelectMaidPage) ||
      (!this.isBookingPage && !this.isSelectMaidPage)
    )
  }
  get shouldShowOneTimeBtn() {
    return (
      (this.selectType === 'one-time' && this.isSelectMaidPage) ||
      (!this.isBookingPage && !this.isSelectMaidPage)
    )
  }

  get thirdDot() {
    return {
      key: 'thirdDot',
      dot: {
        class: 'custom-dot-gray',
      },
      dates: this.calendarTotalHours
        .filter((d) => {
          const isDisabledDate = isDateInTimeSlots(d.date, [
            ...this.disabledDates,
            ...this.baseDisabledHighlight,
          ])
          return !isDisabledDate && d.total_hours >= 6
        })
        .map((d) => this.$momentTZ(d.date).locale(this.$i18n.locale).format()),
    }
  }

  get popovers() {
    return this.disabledDates.map((d) => ({
      key: 'popover',
      popover: {
        label: this.$t('form_label.not_available'),
        placement : 'auto',
      },
      dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
    }))
  }

  get disabledDatesHighlight() {
    return this.disabledDates.map((d) => ({
      key: 'busy',
      highlight: 'gray',
      content: 'gray',
      dates: this.$momentTZ(d.start_datetime).locale(this.$i18n.locale).format(),
    }))
  }

  get calendarAttrs() {
    return [
      this.todayHighlight,
      this.currentSelectDateHighlight,
      ...this.popovers,
      ...this.baseDisabledHighlight,
      this.firstDot,
      this.secondDot,
      this.thirdDot,
      ...this.disabledDatesHighlight,
    ]
  }

  get minDate() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  // ============ End Calendar Attributes ============

  selectedDate = null

  closeModal() {
    localStorage.removeItem('selectProfile')
    this.$root.$emit('bv::hide::modal', 'maid-modal')
  }
  chooseMaid(type) {
    if(this.isCallbackMyBooking){
      this.selectMaid();
      this.$root.$emit('bv::hide::modal', 'maid-modal')
      return;
    } 
    if(this.$route.name == "ChatMessage"){
      this.$store.commit('booking/resetState')
    }
    this.setMaidForBooking({ type, maid: this.detail })
    this.$root.$emit('bv::hide::modal', 'maid-modal')
    if(type == 'one-time'){
      this.$router.push({ name: `BookingOneTime`, query: { type: 'one-time' } })
    } else if(type == 'multi-package') {
      this.$router.push({ name: `BookingMultiPackage`, query: { type: 'multi-package' } })
    }
    window.sessionStorage.setItem('maid_detail', JSON.stringify(this.detail))
  }

  async selectMaid() {
    await this.$store.commit('calendar/resetMaidCalendar')
    const route = this.$route
    // this.setMaidForBooking({ type: this.selectType, maid: this.detail })
    this.setMaidForBooking({ type: this.selectType, maid: { ...this.detail, selected_time: this.maidDetail.selected_time, selected_date: this.maidDetail.selected_date }})
    switch (this.selectType) {
      case 'one-time':
        this.$store.commit('booking/setPreventFetchBooking', true)
        route.query.callback
          ? this.$router.push({
              path: route.query.callback,
              query: { load: false, from: route.query.from },
            })
          : this.$router.push({ name: 'BookingOneTime', query: { type: 'one-time' } })
        break
      case 'multi-package':
        this.$store.commit('booking/setPreventFetchBooking', true)
        route.query.callback
          ? this.$router.push({
              path: route.query.callback,
              query: { load: false, from: route.query.from },
            })
          : this.$router.push({ name: 'BookingMultiPackage', query: { type: 'multi-package' } })
        break
      default:
        break
    }
  }

  get maidID() {
    return this.detail.id
  }

  get locationName() {
    if (!this.maidDetail.location_name) return ''
    return this.maidDetail.location_name
  }

  get mapSrc() {
    return `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=Bangkok`
  }

  get centerMaps() {
    console.log('this.maidDetail', this.maidDetail)
    if (this.maidDetail.location_latitude && this.maidDetail.location_longitude) {
      return {
        lat: this.maidDetail.location_latitude,
        lng: this.maidDetail.location_longitude,
      }
    }
    return { ...defaultMapsCenter }
  }

  initMaps() {
    // console.log("maps", this.$refs.maps);

    let maps = new google.maps.Map(this.$refs.maps, {
      zoom: 14,
      center: this.centerMaps,
      mapTypeId: 'terrain',
    })

    let marker = new google.maps.Marker({
      position: this.centerMaps,
      map: maps,
      title: 'Click to zoom',
    })
  }

  async checkIsFav() {
    try {
      const result = await ApiClient.maidFavorite(this.maidID)
      if (result.data) {
        this.isFavoriteMaid = true
      } else {
        this.isFavoriteMaid = false // result.data is empty object
      }
    } catch (e) {
      console.log(e.response)
    }
  }

  async resetModal() {
    // console.log('ffff')
    this.start = false
    const isBookingPages = [
      'BookingOneTime',
      'BookingMultiPackage',
      'MyBookingOneTime',
      'MyBookingMultiPackage',
    ].includes(this.$route.name)
    this.isFavoriteMaid = false
    await this.resetMaidModal()
    // console.log('reset')
    !isBookingPages && this.resetMaidCalendar()
    this.selectedDate = null
    this.maidDetail = {
      bring_equipment: false,
      location_name: '',
      location_radius: 0,
      location_latitude: 0,
      location_longitude: 0,
      description: '',
      facebook_verified: false,
      criminal_checked: false,
      character_checked: false,
      fcm_token: null,
      cover_images: [],
      service_types: [],
      languages: [],
      additional_infos: [],
      rating: 0,
      no_of_reviews: 0,
      total_hours: 0,
      multi_package_hours: 0,
      repeat_rate: 0,
      level: 'No Level',
      user: {
        first_name: '',
        last_name: '',
      },
      total_works: 0,
      selected_date: null,
      selected_time: null,
    }
    this.currentPage = 1
    localStorage.removeItem('selectProfile')
    this.$store.commit('maid/setIsShowModal', false)
    // this.isLoad = true;
  }

  async toggleFavMaid() {
    console.log('Toggle_2')
    let result
    if (this.isFavoriteMaid) {
      result = await this.unSaveToFavMaid(this.maidID)
    } else {
      result = await this.saveToFavMaid(this.maidID)
    }
    if (result) {
      this.isFavoriteMaid = !this.isFavoriteMaid
    }
  }

  get rating() {
    return this.maidDetail.rating
      ? parseFloat(this.maidDetail.rating).toFixed(1)
      : this.$t('alias.no_rating')
  }

  get starRating() {
    return parseInt(this.maidDetail.rating) > 0 ? 1 : 0
  }

  get serviceTypes() {
    return this.maidDetail.service_types
      .map((type) => this.$t(`services.${type.toLowerCase()}`))
      .join(', ')
  }

  get languages() {
    return this.maidDetail.languages.join(', ')
  }

  @Watch('detail', { deep: true })
  async onDetailChanged() {
    if (!this.detail.id) return
    this.reviews = []
    await this.getMaidDetail()
    this.$store.commit('maid/setIsShowModal', true)
    await this.getReviews(1, 0)
    // await this.getMaidFriend()
    await this.checkIsFav()
    const now = new Date()

    const payload = {
      id: this.maidID,
      month: now.getMonth() + 1,
      year: now.getFullYear(),
      isMe: this.isLoggedIn,
    }
    this.calendarComponentKey += 1
    this.isLoggedIn && (await this.fetchMaidCalendar(payload))
    // this.initMaps()
  }

  async getMaidDetail() {
    try {
      const result = await ApiClient.maidDetail(this.maidID)
      Object.assign(this.maidDetail, result.data)
    } catch (e) {
      console.log(e.response)
    }
  }

  async getMaidFriend() {
    try {
      const result = await ApiClient.maidFriend(this.maidID)
      this.friendsWhoUseThisMaid = result.data
    } catch (e) {
      console.log(e.response)
    }
  }

  deleteReview() {
    this.getReviews(1, 0)
  }

  @Watch('currentPage')
  async getReviews(newPage) {
    console.log('on curentPage change, newPage', newPage)
    try {
      const result = await ApiClient.maidReviewListLastest(this.maidID, {
        page: newPage,
        limit: this.noOfReviewsPerPage,
      })
      if (result) {
        const { page_count, pages, reviews, item_count } = result.data
        this.totalReviews = item_count
        this.reviews = reviews
      }
    } catch (e) {
      console.log(e.response)
    }
  }
  calendarComponentKey = 1
  async fetchMaidCalendar(payload = null) {
    console.log('isLogin', this.isLoggedIn, payload)
    await this.fetchMaidCalendarTotalHours(payload)
    // this.isLoad = true;
    let maidCalendar = null
    if (this.isLoggedIn) {
      maidCalendar = await this.fetchMaidCalendarAllMonth(payload)
    } else {
      maidCalendar = await this.fetchMaidCalendarAllMonthPublic(payload)
    }
    console.log('maidCalendar', maidCalendar)
    this.disabledDates = []
    this.disabledDates = getDisabledDateFromTimeSlot(maidCalendar)
    // this.isLoad = true;
  }

  get shareURL() {
    return `${window.location.origin}/maids/${this.maidID}`
  }

  getReviewAvatar(review) {
    return !review.anonymous
      ? review.user.image_url
      : require('../../assets/images/preview-upload.png')
  }

  onSelectedDate(date) {
    console.log('maid detail', date)
    const isDisabledDate = this.disabledDatesHighlight.concat(this.baseDisabledHighlight).some(({ dates }) => {
      const today = isToday(moment(date.dateTime).toISOString())
      // console.log("isDisabledDate",moment(date.dateTime).isSame(moment(dates), 'date'),today,moment(date.dateTime));
      return moment(date.id).isSame(moment(dates), 'date') || today
    })

    // console.log("isDisabledDate",isDisabledDate);
    if (isDisabledDate) return
    // const offset =
    //   -(new Date().getTimezoneOffset() / 60) < 0 ? 0 : -(new Date().getTimezoneOffset() / 60)
    this.selectedDate = moment.tz(date.id+" 00:00:00",'YYYY-MM-DD HH:mm:ss','UTC').toISOString()
    localStorage.setItem('selectProfile', this.selectedDate)
    this.setCurrentSelectedOnCalendar(this.selectedDate)
  }

  async onMonthChanged(page) {
    this.$store.commit('calendar/setCurrentBookingId', null)
    if (this.isLoggedIn) {
      await this.fetchMaidCalendar({
        id: this.maidID,
        ...page,
        isMe: this.isLoggedIn,
      })
    } else {
      console.log('no login')
      await this.getMaidBookingTotal({ id: this.maidID, ...page })
    }
  }

  async getMaidBookingTotal(payload) {
    try {
      // this.isLoad = false;
      const maidCalendar = this.isLoggedIn
        ? await this.fetchMaidCalendarAllMonth(payload)
        : await this.fetchMaidCalendarAllMonthPublic(payload)
      this.disabledDates = getDisabledDateFromTimeSlot(maidCalendar)
    } catch (e) {
      console.log('yess')
      console.log(e)
    }
  }

  get hasCoverImages() {
    return this.maidDetail.cover_images.length > 0
  }

  get hasCleaningService() {
    return this.maidDetail.service_types.find(
      (service) => `${service}`.toLowerCase() === 'cleaning'
    )
  }

  get hasIroningService() {
    return this.maidDetail.service_types.find((service) =>
      ['ironing', 'drapery'].includes(`${service}`.toLowerCase())
    )
  }

  get bringEquipment() {
    return this.maidDetail.bring_equipment
  }

  get canSpeakTH() {
    return this.maidDetail.languages.includes('THAI') || true
  }

  get canSpeakEN() {
    return this.maidDetail.languages.includes('ENGLISH')
  }

  get canSpeakJP() {
    return this.maidDetail.languages.includes('JAPANESE')
  }

  get canDriveCar() {
    return this.maidDetail.additional_infos.includes('DRIVE_CAR')
  }

  get canDriveMotorcycle() {
    return this.maidDetail.additional_infos.includes('DRIVE_MOTORCYCLE')
  }

  get allergicDogs() {
    return this.maidDetail.additional_infos.includes('ALLERGIC_DOG')
  }

  get allergicCats() {
    return this.maidDetail.additional_infos.includes('ALLERGIC_CAT')
  }

  get repeatRate() {
    const rate = Math.ceil(this.maidDetail.repeat_rate)
    return rate > 0 ? rate : 0
  }
}
</script>
<style scoped lang="scss">
.close {
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
i {
  cursor: pointer;
  &.text-red {
    color: #ff695b !important;
  }
}
.div-plus {
  text-align: end;
  position: relative;
  // right: 0%;
  // top: 5%;
  bottom: 45px;
}
.plus {
  cursor: pointer !important;
  position: relative;
  // position: absolute;
}
@media only screen and (min-width: 300px) {
  .vaccine_avatar {
    display: none;
  }
  .vaccine_avatar_mobile {
    display: block;
    width: 128px;
    height: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .vaccine_avatar {
    display: block;
  }
  .vaccine_avatar_mobile {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .div-plus {
    text-align: end;
    position: relative;
    // right: 0%;
    // top: 5%;
    bottom: 0px;
  }
}

@media screen and (max-width: 768px) {
  .maid-option-span {
    width: 66px;
    text-align: right;
    flex-shrink: 0;
  }

  .maid-option-div{
    flex-wrap: nowrap !important;
    align-items: flex-start !important;
  }

}

.text-light-gray {
  color: #e3e3e3;
}

.font-small {
  font-size: 0.85em;
}

.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(135deg,#000 30%,#0005,#000 50%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite alternate;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

.rotate-animation {
  animation: rotate-animation 1s linear infinite alternate;
}

@keyframes rotate-animation {
  50% {transform: rotate(10deg)}
  0%,100% {transform: rotate(-10deg)}
}

.opacity-animation {
  animation: opacity-animation 1.5s linear infinite alternate;
}

@keyframes opacity-animation {
  50% {opacity: 0}
  0%,100% {opacity: 1}
}

.map-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.map-iframe {
  width: 89%;
  height: 350px;
  transform: scale(1.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .map-iframe {
    width: 100%;
    transform: scale(1);
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
<style>
#image-view-modal___BV_modal_body_ {
  padding: 0;
}

#image-view-modal___BV_modal_content_ {
  background-color: transparent;
  border: none;
}
</style>
