<template>
  <b-container>
    <b-row>
      <b-col sm="12" md="auto" class="mb-3 mb-sm-0">
        <span>© {{ $t('footer.name_company') }} </span><br />
        <span class="ml-3">{{ $t('footer.address_1') }}</span
        ><br />
        <span class="ml-3">{{ $t('footer.address_2') }}</span
        ><br />
        <span class="ml-3">{{ $t('footer.address_3') }}</span>
      </b-col>
      <b-col>
        <ul class="list-inline mb-0 text-left text-md-right">
          <li class="list-inline-item">
            <b-link :to="{ name: 'Terms' }" style="font-size: 16px"
              >{{ $t('footer.terms_of_use') }}
            </b-link>
          </li>
          <li class="list-inline-item">
            <b-link :to="{ name: 'PrivacyAndPolicy' }" style="font-size: 16px"
              >• {{ $t('footer.privacy_policy') }}</b-link
            >
          </li>
          <li class="list-inline-item">
            <b-link :to="{ name: 'UserDataDeletionInstructions' }" style="font-size: 16px"
              >• {{ $t('footer.user_deletion') }}</b-link
            >
          </li>
        </ul>
        <div class="d-none d-sm-block">
          <div class="d-flex flex-row-reverse">
            <div class="p-2">
              <span> <a href="https://line.me/R/ti/p/@bluuu" target="_blank">@bluuu</a></span>
              <br />
              <div v-if="isShowTelephone">
                <span> <a href="tel:020556768">020556768</a></span
                ><br />
              </div>
              <span> info@bluuu.co</span><br />
            </div>
            <div class="p-2">
              <a href="https://line.me/R/ti/p/@bluuu" target="_blank"
                ><img alt="line" src="../assets/images/icons/line.png" /></a
              ><br />
              <div v-if="isShowTelephone">
                <a href="tel:020556768"
                  ><img alt="phone" src="../assets/images/icons/phone.png" /></a
                ><br />
              </div>
              <img alt="email" src="../assets/images/icons/email.png" /><br />
            </div>
            <div class="p-2 text-end">
              {{ $t('footer.contact_us') }}
              <div>{{ $t('footer.available_tme') }}</div>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <div class="d-flex flex-row">
            <div class="p-2">{{ $t('footer.contact_us') }}</div>
            <div class="p-2">
              <div>&nbsp;</div>
              <a href="https://line.me/R/ti/p/@bluuu"
                ><img alt="line" src="../assets/images/icons/line.png" /></a
              ><br />
              <div v-if="isShowTelephone">
                <a href="tel:020556768"
                  ><img alt="phone" src="../assets/images/icons/phone.png" /></a
                ><br />
              </div>
              <img alt="email" src="../assets/images/icons/email.png" /><br />
            </div>
            <div class="p-2">
              <div class="no-icon">{{ $t('footer.available_tme') }}</div>
              <span> <a href="https://line.me/R/ti/p/@bluuu">@bluuu</a></span
              ><br />
              <div v-if="isShowTelephone">
                <span> <a href="tel:020556768">020556768</a></span
                ><br />
              </div>
              <span> info@bluuu.co</span><br />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="text-timestamp">{{ timezone }} {{ isBKKtime ? '/' : '' }}</div>
      </b-col>
    </b-row>
    <BaseModal size="lg" id="service-policy-modal" :scrollable="true">
      <ServicesContent v-if="showScopeServiceContent" />
      <PrivacyPolicyContent v-if="showPolicyContent" />
      <TermsAndConditionContent v-if="showToSContent" />
    </BaseModal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BaseModal from '../components/Modal/BaseModal'
import ServicesContent from '../components/Modal/ServicesContent'
import TermsAndConditionContent from '../components/Modal/TermsAndConditionContent'
import PrivacyPolicyContent from '../components/Modal/PrivacyPolicyContent'

@Component({
  components: {
    BaseModal,
    ServicesContent,
    TermsAndConditionContent,
    PrivacyPolicyContent,
  },
})
export default class Footer extends Vue {
  contentType = ''
  telephone_hidden_start_date = new Date('Oct 9 2022 08:00:00 GMT+0700 (Indochina Time)')
  telephone_hidden_end_date = new Date('Oct 20 2022 08:00:00 GMT+0700 (Indochina Time)')
  timezone = new Date().toString()
  isBKKtime = false

  openModal(type) {
    this.contentType = type
    this.$root.$emit('bv::show::modal', 'service-policy-modal')
  }

  get isShowTelephone() {
    const date = new Date(new Date().toLocaleString('en', { timezone: 'Asia/Bangkok' }))
    return date < this.telephone_hidden_start_date || date > this.telephone_hidden_end_date
  }

  get showScopeServiceContent() {
    return this.contentType === 'scope-of-service'
  }

  get showPolicyContent() {
    return this.contentType === 'policies'
  }

  get showToSContent() {
    return this.contentType === 'terms-condition'
  }

  checkTimeCurrent() {
    const current_date = localStorage.getItem('currentDate') || new Date()
    const currentTime = new Date().getTime()
    const dateThreshold = 1000 * 60 * 60 // 1 hour in milliseconds
    const wrong_current_date =
      current_date && Math.abs(new Date(current_date).getTime() - currentTime) > dateThreshold
    return !wrong_current_date
  }

  interval = null
  mounted() {
    this.timezone = new Date().toString()
    this.isBKKtime = this.checkTimeCurrent()
    this.interval = setInterval(() => {
      this.timezone = new Date().toString()
      this.isBKKtime = this.checkTimeCurrent()
    }, 1000)
  }

  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
<style lang="scss" scoped>
.text-timestamp {
  font-size: 10px;
  margin-top: 0px;
  color: #4a556a;
}

.no-icon {
  margin-left: -38px;
} 

.text-end{
  text-align: end;
}
</style>
